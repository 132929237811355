<template>
  <div class="row">
    <div class="col-12">
      <q-form ref="editForm">
        <c-card title="위험형태 상세" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="초기화" icon="autorenew" @btnClicked="reset" />
              <c-btn v-if="editable" label="저장" icon="save" @btnClicked="save"/>
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-6">
              <c-text
                :editable="editable"
                :required="true"
                label="위험형태"
                name="col1"
                v-model="riskType.col1">
              </c-text>
            </div>
            <div class="col-6">
              <c-radio
                :editable="editable"
                :comboItems="useFlagItems"
                label="사용여부"
                name="useFlag"
                v-model="riskType.useFlag">
              </c-radio>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'kpsr-risk-type',
  props: {
    selectedRow: {
      type: Object,
      default: () => ({
        id: '',
        col1: '',
        useFlag: '',
        useFlagNm: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      riskType: {
        id: '',
        col1: '',
        useFlag: 'Y',
      },
      useFlagItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    selectedRow: {
      handler: function () {
        this.riskType = this.$_.clone(this.selectedRow);
      },
      deep: true,
    },
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      // role setting
      // url setting
      // code setting
      // list setting
      this.riskType = this.$_.clone(this.selectedRow);
    },
    save() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.$emit('saveGuideword', this.riskType);
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내',
            message: '필수 입력값을 입력해 주세요.', // 필수 입력값을 입력해 주세요.
            type: 'warning', // success / info / warning / error
          });
        }
      });
    },
    reset() {
      Object.assign(this.$data.riskType, this.$options.data().riskType);
      let date = new Date();
      this.riskType.id = String(date.getTime())
    }
  }
};
</script>
